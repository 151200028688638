import Image from "next/image";
import React from "react";
import { PrimaryButton } from "../components/forms/Button";
import { HeadMeta } from "../components/HeadMeta";
import onionImage from '../public/placeholders/onion.png';

export default function Custom404() {
    return (
        <>
            <HeadMeta
                title="Not found"
                description="We couldn't find what you were looking for"
            />

            <div className="max-w-5xl mx-auto flex justify-center items-center min-h-screen px-8">
                <div className="flex justify-start flex-col lg:flex-row items-center space-x-10">
                    <Image
                        src={onionImage}
                        width={320}
                        height={320}
                        placeholder="blur"
                        className="opacity-80 max-w-md"
                        priority
                        alt="Onion illustration"
                    />

                    <div className="space-y-8 max-w-xl ml:max-w-max -mt-8">
                        <h1 className="tracking-tigh font-black text-gray-900 antialiased text-8xl">
                            404
                        </h1>
                        <h2 className="tracking-thigther font-normal text-gray-900 antialiased text-3xl lg:text-4xl">
                            Sorry, don&apos;t cry, but we couldn&apos;t find what you
                            were looking for...
                        </h2>
                        <h3 className="text-xl lg:text-2xl text-gray-900 tracking-tigh font-normal">
                            If you think this is an error, contact{" "}
                            <a
                                href="mailto:jorge@fork.club"
                                className="font-bold hover:underline"
                            >
                                support
                            </a>
                            .
                        </h3>

                        <PrimaryButton size="xl" href="/">
                            Go to the landing page
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </>
    );
}
